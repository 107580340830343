<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import useProducts from "../../../hooks/useProducts";
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router/auto";
import ProductLayout from "../../../layouts/ProductLayout.vue";
import { setPageTitle } from "../../../utils/pageTitle";
import { useThemeStore } from "@/stores/themeStore";
import {
  connectToServerApi
} from "@/utils/agGridUtils";
import Warehouses from '@/components/common/Warehouses.vue';

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const {
  productLoadingCount,
  fetchProduct
} = useProducts();

const product = ref(null);
const route = useRoute();
const themeStore = useThemeStore();

const OUTBOUND_ORDERS_URL = `/products/${route.params.id}/outbound_order_items`;
const PRODUCT_EXTERNAL_FILTERS_KEY = "products-external-filter";

let gridApi;

const context = ref(null);

onBeforeMount(async () => {
  product.value = await fetchProduct(route.params.id);
  setPageTitle(`Product -${product.value.id}`);
});

const onGridReady = async (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl: OUTBOUND_ORDERS_URL,
    filterKey: PRODUCT_EXTERNAL_FILTERS_KEY,
    advancedFilterKeys: []
  }
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const columnDefs = [
  { field: "id", headerName: "Outbound #", cellDataType: "text" },
  { field: "order.order_status.name", headerName: "Status", cellDataType: "text" },
  { field: "order.warehouse.nickname", headerName: "Warehouse", cellDataType: "text" },
  { field: "order.appt.window_date", headerName: "Appt", cellDataType: "text" },
  { field: "order.po_number", headerName: "PO Number", cellDataType: "text" },
  { field: "qty", headerName: "Qty", cellDataType: "text" },
  { field: "product.units", headerName: "Units", cellDataType: "text" },
  { field: "qty_allocated", headerName: "Allocated", cellDataType: "text" },
  { field: "qty_picked", headerName: "Picked", cellDataType: "text" },
  { field: "qty_shipped", headerName: "Shipped", cellDataType: "text" },
  { field: "created_at", headerName: "Created At", cellDataType: "text" },
  { field: "order.shipped_at", headerName: "Shipped At", cellDataType: "text" },
  { field: "order.eta", headerName: "Ship On", cellDataType: "text" },
];

const gridOptions = {
  rowModelType: "serverSide"
};

const defaultColDef = {
  sortable: true,
  suppressHeaderMenuButton: true,
  width: 120,
};

const warehouseFiltersChangedCallback = () => {
  gridApi.onFilterChanged();
}

</script>

<template>
  <div id="grid-wrapper-outbound">
    <Warehouses class="mb-6" :callBack="warehouseFiltersChangedCallback" :parent-filter="PRODUCT_EXTERNAL_FILTERS_KEY" />
    <ProductLayout :product="product">
      <div class="ml-4 mb-4">
        <h4 class="text-center">Outbound Activities</h4>
      </div>
      <div class="outbound-table">
        <AgGridVue style="width: 100%; height: 100%" :class="themeStore.agTheme" :columnDefs="columnDefs"
          :defaultColDef="defaultColDef" :gridOptions="gridOptions" @grid-ready="onGridReady" :context="context">
        </AgGridVue>
      </div>
    </ProductLayout>
  </div>
</template>

<style scoped>
#grid-wrapper-outbound {
  width: 100%;
  height: 100%;
}

.outbound-table {
  padding-left: 10px;
  padding-right: 10px;
  height: 600px;
}
</style>
